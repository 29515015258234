<div class="container-fluid" *ngIf="accounts.length">
	<ul class="columns">
		<!-- CARD 1 -->
		<li class="card column col-4 col-md-12">
			<a class="card-horizontal text-center">
				<!-- icon -->
				<div class="card-icon">
					<svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M22.289 48.013v4.07c0 4.11 9.034 7.011 20.288 7.083H43c11.45 0 20.712-2.922 20.712-7.082v-4.071a.8.8 0 0 0-1.6 0v4.07c0 2.743-8.339 5.41-18.686 5.482l-.426.002c-10.543 0-19.111-2.704-19.111-5.483v-4.071a.8.8 0 0 0-1.6 0Z" fill="#DB0029" />
						<path d="M22.289 48.013c0 4.11 9.034 7.01 20.288 7.082l.423.001c11.45 0 20.712-2.922 20.712-7.083 0-1.996-2.014-3.652-5.464-4.893a.8.8 0 1 0-.541 1.505c2.873 1.034 4.405 2.294 4.405 3.388 0 2.742-8.339 5.41-18.686 5.482l-.426.001c-10.4 0-18.88-2.631-19.107-5.37l-.004-.113a.8.8 0 0 0-1.6 0Z" fill="#DB0029" />
						<path d="M58.712 38.014v4.07c0 4.11-9.034 7.011-20.289 7.082l-.423.002c-11.45 0-20.711-2.923-20.711-7.084v-4.07h1.6v4.07c0 2.742 8.338 5.41 18.685 5.482l.426.002c10.544 0 19.112-2.704 19.112-5.483v-4.071h1.6Z" fill="#333" />
						<path d="M58.712 38.013c0 4.11-9.034 7.011-20.289 7.082l-.423.002c-11.45 0-20.711-2.923-20.711-7.084 0-2.02 2.065-3.694 5.598-4.94l.532 1.508c-2.95 1.041-4.53 2.321-4.53 3.432 0 2.742 8.338 5.41 18.685 5.482l.426.002c10.544 0 19.112-2.704 19.112-5.484h1.6Z" fill="#333" />
						<path d="M22.289 29.014v4.07c0 4.11 9.034 7.011 20.288 7.082l.423.002c11.45 0 20.712-2.923 20.712-7.084v-4.07h-1.6v4.07c0 2.742-8.339 5.41-18.686 5.482l-.426.002c-10.543 0-19.111-2.704-19.111-5.483v-4.071h-1.6Z" fill="#DB0029" />
						<path d="M63.712 29.014c0 4.109-9.034 7.01-20.289 7.081h-.846c-11.254-.07-20.288-2.972-20.288-7.081 0-4.11 9.034-7.011 20.288-7.082h.846c11.255.07 20.289 2.972 20.289 7.082Zm-21.138-5.482-.846.012c-9.952.206-17.84 2.803-17.84 5.47 0 2.741 8.339 5.41 18.686 5.481h.852l.846-.011c9.952-.207 17.84-2.804 17.84-5.47 0-2.742-8.339-5.41-18.686-5.482h-.852Z" fill="#DB0029" />
					</svg>
				</div>
				<!-- text -->
				<div class="card-text">
					<h2 class="card-title text-dark">{{total.toLocaleString()}} so'm</h2><br>
					<div class="text-custom">
						<h4>Umumiy qiymat</h4>
					</div>
				</div>
			</a>
		</li>

	</ul>
  <div class="row">
    <div class="col-6" *ngFor="let item of accounts; let i=index">
      <ul class="columns">
        <!-- CARD 1 -->
        <li class="column col-4 col-md-12 text-center card ">
          <div class="d-flex justify-content-end">
            <span class="text-danger remove-icon" (click)="removeContract(i)">✕</span>
          </div>
          <label for="">{{i+1}}-si</label>
          <a class="card-horizontal">
            <!-- icon -->
            <div class="card-icon">
              <svg width="80" height="80" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M22.289 48.013v4.07c0 4.11 9.034 7.011 20.288 7.083H43c11.45 0 20.712-2.922 20.712-7.082v-4.071a.8.8 0 0 0-1.6 0v4.07c0 2.743-8.339 5.41-18.686 5.482l-.426.002c-10.543 0-19.111-2.704-19.111-5.483v-4.071a.8.8 0 0 0-1.6 0Z" fill="#DB0029" />
                <path d="M22.289 48.013c0 4.11 9.034 7.01 20.288 7.082l.423.001c11.45 0 20.712-2.922 20.712-7.083 0-1.996-2.014-3.652-5.464-4.893a.8.8 0 1 0-.541 1.505c2.873 1.034 4.405 2.294 4.405 3.388 0 2.742-8.339 5.41-18.686 5.482l-.426.001c-10.4 0-18.88-2.631-19.107-5.37l-.004-.113a.8.8 0 0 0-1.6 0Z" fill="#DB0029" />
                <path d="M58.712 38.014v4.07c0 4.11-9.034 7.011-20.289 7.082l-.423.002c-11.45 0-20.711-2.923-20.711-7.084v-4.07h1.6v4.07c0 2.742 8.338 5.41 18.685 5.482l.426.002c10.544 0 19.112-2.704 19.112-5.483v-4.071h1.6Z" fill="#333" />
                <path d="M58.712 38.013c0 4.11-9.034 7.011-20.289 7.082l-.423.002c-11.45 0-20.711-2.923-20.711-7.084 0-2.02 2.065-3.694 5.598-4.94l.532 1.508c-2.95 1.041-4.53 2.321-4.53 3.432 0 2.742 8.338 5.41 18.685 5.482l.426.002c10.544 0 19.112-2.704 19.112-5.484h1.6Z" fill="#333" />
                <path d="M22.289 29.014v4.07c0 4.11 9.034 7.011 20.288 7.082l.423.002c11.45 0 20.712-2.923 20.712-7.084v-4.07h-1.6v4.07c0 2.742-8.339 5.41-18.686 5.482l-.426.002c-10.543 0-19.111-2.704-19.111-5.483v-4.071h-1.6Z" fill="#DB0029" />
                <path d="M63.712 29.014c0 4.109-9.034 7.01-20.289 7.081h-.846c-11.254-.07-20.288-2.972-20.288-7.081 0-4.11 9.034-7.011 20.288-7.082h.846c11.255.07 20.289 2.972 20.289 7.082Zm-21.138-5.482-.846.012c-9.952.206-17.84 2.803-17.84 5.47 0 2.741 8.339 5.41 18.686 5.481h.852l.846-.011c9.952-.207 17.84-2.804 17.84-5.47 0-2.742-8.339-5.41-18.686-5.482h-.852Z" fill="#DB0029" />
              </svg>
            </div>
            <!-- text -->
            <div class="card-text">
              <h2 class="card-title text-dark">{{item.sum.toLocaleString()}} so'm</h2><br>
              <div class="card-subtitle text-custom">
                <span (click)="showContract(i)" style="cursor: pointer">O'zgarish kiritish</span>
                <span class="icon-after">
                  <svg class="icon svg-icon icon_chevron_right" aria-hidden="true" width="24" height="24" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
                    <path d="M368.013 256.003c0 4.528-1.92 8.864-5.296 11.888l-160 144c-6.56 5.904-16.688 5.376-22.592-1.184-5.936-6.544-5.408-16.672 1.2-22.592l146.768-132.112-146.768-132.112c-6.576-5.904-7.088-16.032-1.2-22.592 5.904-6.576 16.032-7.104 22.592-1.184l160 144a15.969 15.969 0 015.296 11.888" fill="#db0029" fill-rule="evenodd"></path>
                  </svg>
                </span>
              </div>
            </div>
          </a>
        </li>

      </ul>
    </div>
  </div>
</div>
