import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-invest',
  templateUrl: './contract-invest.component.html',
  styleUrls: ['./contract-invest.component.scss']
})
export class ContractInvestComponent implements OnInit {

  public form: FormGroup;
  public lightValue: number = 3558420;
  public standartValue: number = 5535320;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any


  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        applicantType: new FormControl("Yuridik shaxs", Validators.required),
        memoryType: new FormControl(null, Validators.required),
        capacity: new FormControl(null, Validators.required),
        serviceType: new FormControl(null, Validators.required)
      }
    )
    if(localStorage.getItem('contract-invest')) {
      const formData = JSON.parse(localStorage.getItem('contract-invest'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    if(this.form.value.serviceType == 1) {
      if(this.form.value.memoryType == 1) {
        ssd.map(item => {
          if(this.form.value.capacity>item.from && this.form.value.capacity<=item.to) {
            this.approximateAmount = item.analise+item.size[1].price;
          }
        })
      } else if(this.form.value.memoryType == 2) {
        ssd.map(item => {
          if(this.form.value.capacity>item.from && this.form.value.capacity<=item.to) {
            this.approximateAmount = item.analise+item.size[1].price;
          }
        })
      } else if(this.form.value.memoryType == 3) {
        ssd.map(item => {
          if(this.form.value.capacity>item.from && this.form.value.capacity<=item.to) {
            this.approximateAmount = item.analise+item.size[1].price;
          }
        })
      }
    } else if(this.form.value.serviceType == 2) {
      if(this.form.value.memoryType == 1) {
        this.approximateAmount = 550000
      } else if(this.form.value.memoryType == 2) {
        this.approximateAmount = 550000
      } else if(this.form.value.memoryType == 3) {
        this.approximateAmount = 300000
      }
    }
    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    if(this.form.value.serviceType == 1) {
      this.form.value.serviceType = "Raqamli ma'lumotlarni tiklash"
    } else if(this.form.value.serviceType == 2) {
      this.form.value.serviceType ="Raqamli ma'lumotlarni yo'q qilish"
    }
    if(this.form.value.memoryType == 1) {
      this.form.value.memoryType = "Qattiq disk (SSD)"
    } else if(this.form.value.memoryType == 2) {
      this.form.value.memoryType = "Qattiq disk (HDD)"
    } else if(this.form.value.memoryType == 3) {
      this.form.value.memoryType = "Flesh xotira"
    }
    localStorage.setItem('title', "Elektron saqlash vositalaridan raqamli ma'lumotlarni qayta tiklash va yo'q qilish bo'yicha xizmatlar")
    localStorage.setItem('contract-reag', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'investigation')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Arizachi turi',
      'Elektron saqlash vositasi turi',
      'Elektron saqlash vositasi hajmi (GB)',
      'Xizmat turi'
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'applicantType',
      'memoryType',
      'capacity',
      'serviceType'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    if(this.contractList[index].serviceType == "Raqamli ma'lumotlarni tiklash") {
      this.contractList[index].serviceType = 1
    } else if(this.contractList[index].serviceType == "Raqamli ma'lumotlarni yo'q qilish") {
      this.contractList[index].serviceType = 2
    }
    if(this.contractList[index].memoryType == "Qattiq disk (SSD)") {
      this.contractList[index].memoryType = 1
    } else if(this.contractList[index].memoryType == "Qattiq disk (HDD)") {
      this.contractList[index].memoryType = 2
    } else if(this.contractList[index].memoryType == "Flesh xotira") {
      this.contractList[index].memoryType = 3
    }
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

}

const hdd = [
  {
    from: 0, to: 320, analise: 250000, size: [
      {from: 0, to: 10, price: 200000},
      {from: 10, to: 150, price: 450000},
      {from: 150, to: 500, price: 550000},
      {from: 500, to: 10000, price: 0}
    ]
  },
  {
    from: 320, to: 500, analise: 350000, size: [
      {from: 0, to: 10, price: 250000},
      {from: 10, to: 150, price: 550000},
      {from: 150, to: 500, price: 650000},
      {from: 500, to: 700, price: 900000},
      {from: 700, to: 10000, price: 0}
    ]
  },
  {
    from: 500, to: 1024, analise: 500000, size: [
      {from: 0, to: 10, price: 350000},
      {from: 10, to: 150, price: 650000},
      {from: 150, to: 500, price: 800000},
      {from: 500, to: 700, price: 1100000},
      {from: 700, to: 10000, price: 0}
    ]
  },
  {
    from: 1025, to: 11000, analise: 600000, size: [
      {from: 0, to: 10, price: 400000},
      {from: 10, to: 150, price: 750000},
      {from: 150, to: 500, price: 950000},
      {from: 500, to: 700, price: 1250000},
      {from: 700, to: 10000, price: 0}
    ]
  }
];

const ssd = [
  {
    from: 0, to: 128, analise: 250000, size: [
      {from: 0, to: 10, price: 200000},
      {from: 10, to: 150, price: 450000},
      {from: 150, to: 500, price: 0}
    ]
  },{
    from: 128, to: 256, analise: 350000, size: [
      {from: 0, to: 10, price: 200000},
      {from: 10, to: 150, price: 450000},
      {from: 150, to: 500, price: 550000},
      {from: 500, to: 10000, price: 0}
    ]
  },{
    from: 256, to: 512, analise: 500000, size: [
      {from: 0, to: 10, price: 350000},
      {from: 10, to: 150, price: 650000},
      {from: 150, to: 500, price: 800000},
      {from: 500, to: 700, price: 1100000},
      {from: 700, to: 10000, price: 0}
    ]
  },{
    from: 512, to: 1024, analise: 600000, size: [
      {from: 0, to: 10, price: 400000},
      {from: 10, to: 150, price: 750000},
      {from: 150, to: 500, price: 950000},
      {from: 500, to: 700, price: 1250000},
      {from: 700, to: 10000, price: 0}
    ]
  },{
    from: 1025, to: 11000, analise: 750000, size: [
      {from: 0, to: 10, price: 550000},
      {from: 10, to: 150, price: 700000},
      {from: 150, to: 500, price: 850000},
      {from: 500, to: 700, price: 950000},
      {from: 700, to: 1024, price: 1200000},
      {from: 1024, to: 10000, price: 0}
    ]
  }
]

const flash = [
  {
    from: 0, to: 4, analise: 150000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000}
    ]
  },
  {
    from: 4, to: 8, analise: 200000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000},
      {from: 30, to: 3000, price: 550000}
    ]
  },
  {
    from: 8, to: 16, analise: 250000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000},
      {from: 30, to: 3000, price: 550000}
    ]
  },
  {
    from: 16, to: 32, analise: 300000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000},
      {from: 30, to: 3000, price: 550000}
    ]
  },
  {
    from: 32, to: 64, analise: 350000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000},
      {from: 30, to: 70, price: 550000},
      {from: 70, to: 7000, price: 700000}
    ]
  },
  {
    from: 64, to: 128, analise: 400000, size: [
      {from: 0, to: 5, price: 100000},
      {from: 5, to: 10, price: 250000},
      {from: 10, to: 30, price: 400000},
      {from: 30, to: 70, price: 550000},
      {from: 70, to: 130, price: 700000},
      {from: 130, to: 1300, price: 950000}
    ]
  }
]
