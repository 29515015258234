import { Router } from '@angular/router';
import { DataShareService } from './../../services/data-share.service';
import { Component, OnInit } from '@angular/core';
import { ExportService } from 'src/app/services/export.service';

@Component({
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit {

  public accounts: any[]=[]
  public total: number=0
  public dataList: any
  public url: string;

  constructor(private dataShareService: DataShareService, private exportService: ExportService,
    private router: Router) {
    this.url = localStorage.getItem('url')
   }

  ngOnInit() {
    this.dataShareService.sharedAccounts.subscribe(data => {
      if(data) {
        this.accounts = data
        this.total = 0;
        this.accounts.map(item => {
          this.total+=item.sum
        })
      }
    })
    this.dataShareService.sharedExportData.subscribe(data => {
      if(data) {
        this.dataList = data
      }
    })
  }

  showContract(index) {
    this.dataShareService.nextEditIndex(index+1)
    this.router.navigate[this.url]
    window.scrollTo(0, 0);
  }

  removeContract(index) {
    this.dataShareService.nextRemoveIndex(index+1)
  }

}
