
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Axborotlashtirish obyektlarining axborot xavfsizligi auditi</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Ishchi stansiyalar:<span class="text-danger">*</span></label>
                    <input type="number" formControlName="workstation" class="form-control w-100 border-0">
                    <div *ngIf="form.get('workstation').invalid && form.get('workstation').touched" class="validation">
                      <small> <i>Ishchi stansiyalar</i> kiritilishi shart</small>
                    </div>
                    <hr>
                  </div>
                  <div class="col-12">
                      <label for="">Serverlar soni:</label>
                      <input type="number" formControlName="server" class="form-control w-100 border-0">
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)*</span>
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                      <button type="button" class="btn btn-lg btn-outline-secondary w-100" [disabled]="!approximateAmount" (click)="addSystem()"> {{(!contractIndex ? 'IS yoki server qo`shish' : 'Tahrir qilish')}}</button>
                  </div>
              </div>
              <div class="d-flex justify-content-start">
                <span class="mt-4">* - Bu taxminiy summa bo'lib, aniq summa shartnomada ko'rsatiladi</span>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
              </div>
          </div>
      </form>
  </header>
  <header class="d-flex justify-content-center" *ngIf="contractList.length">
    <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
      <div class="mb-3 mt-2">
        <app-calculation></app-calculation>
      </div>
    </form>
  </header>
</div>
