<div class="container">
  <header class="d-flex justify-content-center">
    <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
      <div class="row">
        <div class="w-100 bg-primary header-block">
          <span class="mt-4">Xizmatlar</span>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 mt-2" *ngFor="let service of serviceNames; let i=index;">
          <div class="promotion-card light" style="background:white" [routerLink]="service.url">
            <div class="promotion-blob"></div>
            <div class="promotion-content">
              <div class="d-flex justify-content-between">
                <img src="{{service.icon}}" alt="">
                <span *ngIf="service.id==1" class="float-right order">1</span>
                <span *ngIf="service.id==2" class="float-right order">2</span>
                <span *ngIf="service.id==3" class="float-right order">3</span>
              </div>
            <p>{{service.name}}</p>
              </div>
          </div>
        </div>
      </div>
    </form>
  </header>
</div>
<!-- [skipLocationChange]="true" [queryParams]="{url: service.url}" -->
