import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-annual',
  templateUrl: './contract-annual.component.html',
  styleUrls: ['./contract-annual.component.scss']
})
export class ContractAnnualComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 50055108;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any

  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        serviceType: new FormControl(null, [])
      }
    )
    if(localStorage.getItem('contract-pcs')) {
      const formData = JSON.parse(localStorage.getItem('contract-pcs'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    this.approximateAmount = this.constValue;
    this.form.value['sum']=this.approximateAmount
    this.form.value['serviceType']='Barcha hizmatlar';
    this.contractList.push(this.form.value)
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
    localStorage.setItem('title', "Konsalting xizmatlarini ko'rsatish(yillik paketlar)")
    localStorage.setItem('contract-annual', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'interaction')
    localStorage.setItem('columnsMain', JSON.stringify(['Xizmat turi']))
    localStorage.setItem('columnsTemp', JSON.stringify(['serviceType']))
    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
    this.form.reset()
    this.approximateAmount = 0
  }

  back() {
    this.router.navigate(['/'])
  }

}


const yearServices = [
  {id: 1, sum: 9884500, name: "Tashkilotning me'yoriy-huquqiy hujjatlarga muvofiq axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha baholash o'tkazish"},
  {id: 2, sum: 3953800, name: "Tashkilotning himoya ob'ektlarini xavfsizlik darajasi bo'yicha tasniflash, axborot va kiberxavfsizlik holatini baholash, invertarizatsiyzdan o'tkazish"},
  {id: 3, sum: 3953800, name: "Tashkilotdagi konfidensial ma'lumotlar ro'yxatini va uni himoya qilishni ta'minlash choralarini aniqlash"},
  {id: 4, sum: 1976900, name: "Tashkilotda Axborot xavfsizligi siyosatini ishlab chiqish, qayta ko'rib chiqish yoki yangilanishini baholashda yordam berish"},
  {id: 5, sum: 8896050, name: "Tashkilotda axborot va kiberxavfsizlikni ta'minlash uchun ichki me'yoriy-huquqiy hujjatlarni tayyorlashda yordam berish"},
  {id: 6, sum: 790760, name: "Tashkilotni axborot va kiberxavfsizlik sohasida huquqiy qo'llab-quvvatlash"},
  {id: 7, sum: 830298, name: "Tashkilotning axborot va kiberxavfsizligini ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash"},
  {id: 8, sum: 3953800, name: "Tashkiliy chora-tadbirlarni amalga oshirish va axborot xavfsizligi vositalarini joriy etishga ko'maklashish"},
  {id: 9, sum: 6919150, name: "Tashkilotdagi axborot va kiberxavfsizlik hodisalarini hisobga olishni ta'minlash, shuningdek ularning oqibatlarini bartaraf etishda yordam berish"},
  {id: 10, sum: 1976900, name: "Tashkilot xodimlarini axborot va kiberxavfsizlik bilan ta'minlash bo'yicha seminar-trening o'tkazish"},
  {id: 11, sum: 5930700, name: "Axborot va kiberxavfsizlikni ta'minlash uchun mas'ul bo'lgan mutaxassislar uchun treninglar o'tkazish"},
  {id: 12, sum: 980450, name: "Axborot va kiberxavfsizlik monitoringi tizimida reyting ko'rsatkichlarini to'ldirish va oshirish bo'yicha maslahatlar"}
]
