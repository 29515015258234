import { ContractInvestComponent } from './contract/contract-invest/contract-invest.component';
import { ContractReagComponent } from './contract/contract-reag/contract-reag.component';
import { ContractCertComponent } from './contract/contract-cert/contract-cert.component';
import { ContractAuditComponent } from './contract/contract-audit/contract-audit.component';
import { ContractAnnualComponent } from './contract/contract-annual/contract-annual.component';
import { ContractEisctrComponent } from './contract/contract-eisctr/contract-eisctr.component';
import { ContractEtrcicsrComponent } from './contract/contract-etrcicsr/contract-etrcicsr.component';
import { ContractPcsComponent } from './contract/contract-pcs/contract-pcs.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ContactsComponent } from './contract/contacts/contacts.component';
import { RequisiteComponent } from './contract/requisite/requisite.component';
import { ContractComponent } from './contract/contract.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContractDispComponent } from './contract/contract-disp/contract-disp.component';


const routes: Routes = [
  {path: '', component: DashboardComponent, pathMatch: 'full'},
  {path: 'contract', component: ContractComponent},
  {path: 'requisite', component: RequisiteComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'contract-disp', component: ContractDispComponent},
  {path: 'contract-pcs', component: ContractPcsComponent},
  {path: 'contract-etrcicsr', component: ContractEtrcicsrComponent},
  {path: 'contract-eisctr', component: ContractEisctrComponent},
  {path: 'contract-annual', component: ContractAnnualComponent},
  {path: 'contract-audit', component: ContractAuditComponent},
  {path: 'contract-cert', component: ContractCertComponent},
  {path: 'contract-reag', component: ContractReagComponent},
  {path: 'contract-invest', component: ContractInvestComponent},
  {path: '**', redirectTo: '/' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
