import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-cert',
  templateUrl: './contract-cert.component.html',
  styleUrls: ['./contract-cert.component.scss']
})
export class ContractCertComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 363387;
  public approximateAmount: number = 0;
  public roleValue: number = 0;
  public moduleValue: number = 0;
  public systemValue: number = 0;
  public comparedValue: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any
  public isDocument: boolean = true
  @ViewChild('roleCount') roleCount: ElementRef
  @ViewChild('moduleCount') moduleCount: ElementRef
  @ViewChild('systemCount') systemCount: ElementRef


  constructor( private dataShareService: DataShareService, private router: Router ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        type: new FormControl(null, Validators.required),
        isDocument: new FormControl(null, Validators.required),
        copy: new FormControl(1, []),
        rowCount: new FormControl(null, [])
      }
    )
    if(localStorage.getItem('contract')) {
      const formData = JSON.parse(localStorage.getItem('contract'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })
  }

  public selectType(event) {
    let type = this.generateSoftwareType(event.target.value)
    if(type) {
      if(type == 2) {
        this.isDocument = false
      } else {
        this.isDocument = true
      }
    }
  }

  onSubmit() {
    if(this.form.value) {
      // Tarmoqlararo ekran
      if(this.generateSoftwareType(this.form.value.type)  == 1) {
        let capacityValue
        firewalArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
      // Operatsion tizim
      if(this.generateSoftwareType(this.form.value.type) == 2) {
        this.isDocument = false
        let capacityValue
        osArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
      // Axborot tizimi(yagona foydalanuvchili)
      if(this.generateSoftwareType(this.form.value.type) == 3) {
        let capacityValue
        singleArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
      // Axborot tizimi(teng huquqli foydalanuvchilar)
      if(this.generateSoftwareType(this.form.value.type) == 4) {
        let capacityValue
        equalArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
      // Axborot tizimi(har xil huquqli foydalanuvchilar)
      if(this.generateSoftwareType(this.form.value.type) == 5) {
        let capacityValue
        differentArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
      // Axborot tizimi(har xil huquqli foydalanuvchilar) (ДСП grifli hujjatlarga moslashgan)
      if(this.generateSoftwareType(this.form.value.type) == 6) {
        let capacityValue
        dspArray.map(item => {
          if(item.id == this.generatePart(this.form.value.copy)) {
            capacityValue = item.capacity
          }
        })
        this.approximateAmount = this.constValue*(capacityValue+(this.generateTrial(this.form.value.rowCount)*40+1)+17)
      }
    }

    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    localStorage.setItem('title', "Sertifikatlashtirish bo'yicha organ xizmatlari")
    localStorage.setItem('contract', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'certificate')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Dasturiy ta`minot toifasi',
      'Hujjat taqdim etiladi',
      'Dasturiy ta`minot nusxasi soni',
      'Dastur kodi qatorlari soni',
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'type',
      'isDocument',
      'copy',
      'rowCount'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

 // SET LIMIT

  public generatePart(count) {
    let compatibleNumber = 1
    if(count == 1) {
      compatibleNumber = 1
    } else if(count == 2) {
      compatibleNumber = 2
    } else if(count>2 && count <=25) {
      compatibleNumber = 3
    } else if(count>25 && count <=50) {
      compatibleNumber = 4
    } else if(count>50 && count <=90) {
      compatibleNumber = 5
    } else if(count>90 && count <=150) {
      compatibleNumber = 7
    } else if(count>150 && count <=280) {
      compatibleNumber = 10
    } else if(count>280 && count <=500) {
      compatibleNumber = 15
    } else if(count>500 && count <=1200) {
      compatibleNumber = 20
    } else if(count>1200 && count <=3200) {
      compatibleNumber = 25
    } else if(count>2300) {
      compatibleNumber = 35
    }
    return compatibleNumber;
  }

  public generateTrial(count) {
    let compatibleNumber = 1
    if(count <=100) {
      compatibleNumber = 1
    } else if(count>100 && count <= 1000) {
      compatibleNumber = 2
    } else if(count>1000 && count <=10000) {
      compatibleNumber = 3
    } else if(count>10000 && count <=100000) {
      compatibleNumber = 4
    } else if(count>100000 && count <=1000000) {
      compatibleNumber = 5
    } else if(count>1000000 && count <=10000000) {
      compatibleNumber = 6
    } else if(count>10000000) {
      compatibleNumber = 7
    }
    return compatibleNumber;
  }

  public generateSoftwareType(type) {
    let tip = 1
    if(type === "Tarmoqlararo ekran") {
      tip = 1
    } else if(type === "Operatsion tizim") {
      tip = 2
    } else if(type === "Axborot tizimi(yagona foydalanuvchili)") {
      tip = 3
    } else if(type === "Axborot tizimi(teng huquqli foydalanuvchilar)") {
      tip = 4
    } else if(type === "Axborot tizimi(har xil huquqli foydalanuvchilar)") {
      tip = 5
    } else if(type === "Axborot tizimi(har xil huquqli foydalanuvchilar) (ДСП grifli hujjatlarga moslashgan)") {
      tip = 6
    }
    return tip;
  }

}

const firewalArray = [
  {id: 1, capacity: 44},
  {id: 2, capacity: 54},
  {id: 3, capacity: 74},
  {id: 4, capacity: 94},
  {id: 5, capacity: 114},
  {id: 6, capacity: 154.5},
  {id: 7, capacity: 224.5},
  {id: 8, capacity: 304.5},
  {id: 9, capacity: 385},
  {id: 10, capacity: 465},
  {id: 11, capacity: 525}
]


const osArray = [
  {id: 1, capacity: 44},
  {id: 2, capacity: 84},
  {id: 3, capacity: 124},
  {id: 4, capacity: 164},
  {id: 5, capacity: 204},
  {id: 6, capacity: 284},
  {id: 7, capacity: 404},
  {id: 8, capacity: 604},
  {id: 9, capacity: 804},
  {id: 10, capacity: 1004},
  {id: 11, capacity: 1404}
]

const singleArray = [
  {id: 1, capacity: 20},
  {id: 2, capacity: 36},
  {id: 3, capacity: 52},
  {id: 4, capacity: 68},
  {id: 5, capacity: 84},
  {id: 6, capacity: 116},
  {id: 7, capacity: 164},
  {id: 8, capacity: 244},
  {id: 9, capacity: 324},
  {id: 10, capacity: 404},
  {id: 11, capacity: 484}
]

const equalArray = [
  {id: 1, capacity: 20},
  {id: 2, capacity: 36},
  {id: 3, capacity: 52},
  {id: 4, capacity: 68},
  {id: 5, capacity: 84},
  {id: 6, capacity: 116},
  {id: 7, capacity: 164},
  {id: 8, capacity: 244},
  {id: 9, capacity: 324},
  {id: 10, capacity: 404},
  {id: 11, capacity: 484}
]

const differentArray = [
  {id: 1, capacity: 28},
  {id: 2, capacity: 52},
  {id: 3, capacity: 76},
  {id: 4, capacity: 100},
  {id: 5, capacity: 124},
  {id: 6, capacity: 172},
  {id: 7, capacity: 244},
  {id: 8, capacity: 364},
  {id: 9, capacity: 484},
  {id: 10, capacity: 604},
  {id: 11, capacity: 844}
]

const dspArray = [
  {id: 1, capacity: 36},
  {id: 2, capacity: 68},
  {id: 3, capacity: 100},
  {id: 4, capacity: 132},
  {id: 5, capacity: 164},
  {id: 6, capacity: 228},
  {id: 7, capacity: 324},
  {id: 8, capacity: 480},
  {id: 9, capacity: 644},
  {id: 10, capacity: 844},
  {id: 11, capacity: 1124}
]
