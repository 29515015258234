
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Sertifikatlashtirish bo'yicha organ xizmatlari</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Dasturiy ta'minot toifasi:<span class="text-danger">*</span></label>
                    <select name="select" class="form-control w-100" formControlName="type" (click)="selectType($event)">
                      <option value="" disabled></option>
                      <option value="Tarmoqlararo ekran">Tarmoqlararo ekran</option>
                      <option value="Operatsion tizim">Operatsion tizim</option>
                      <option value="Axborot tizimi(yagona foydalanuvchili)">Axborot tizimi(yagona foydalanuvchili)</option>
                      <option value="Axborot tizimi(teng huquqli foydalanuvchilar)">Axborot tizimi(teng huquqli foydalanuvchilar)</option>
                      <option value="Axborot tizimi(har xil huquqli foydalanuvchilar)">Axborot tizimi(har xil huquqli foydalanuvchilar)</option>
                      <option value="Axborot tizimi(har xil huquqli foydalanuvchilar) (ДСП grifli hujjatlarga moslashgan)">Axborot tizimi(har xil huquqli foydalanuvchilar) (ДСП grifli hujjatlarga moslashgan)</option>
                    </select>
                    <div *ngIf="form.get('type').invalid && form.get('type').touched" class="validation">
                      <small> <i>Dasturiy ta'minot toifasi</i> tanlanishi shart</small>
                    </div>
                    <hr>
                  </div>
                  <div class="col-12">
                      <label for="">Hujjat taqdim etiladi<span class="text-danger">*</span></label>
                      <select name="select" class="form-control w-100" formControlName="isDocument">
                        <option value=""></option>
                        <option value="HA">HA</option>
                        <option value="YO'Q">YO'Q</option>
                      </select>
                      <div *ngIf="form.get('isDocument').invalid && form.get('isDocument').touched" class="validation">
                        <small><i>Hujjat taqdim etiladi</i> tanlanishi shart</small>
                      </div>
                      <hr>
                  </div>
                  <div class="col-12" *ngIf="isDocument">
                    <label for="">Dasturiy ta'minot nusxasi soni</label>
                    <input type="number" min="0" pattern="[0-9]*" class="form-control" formControlName="copy">
                    <hr>
                  </div>
                  <div class="col-12">
                    <label for="">Dastur kodi qatorlari soni</label>
                    <input type="number" min="0" pattern="[0-9]*" class="form-control" formControlName="rowCount">
                    <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)*</span>
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                      <button type="button" class="btn btn-lg btn-outline-secondary w-100" [disabled]="!approximateAmount" (click)="addSystem()"> {{(!contractIndex ? 'Dasturiy taminot qoshish' : 'Tahrir qilish')}}</button>
                  </div>
              </div>
                  <div class="d-flex justify-content-start">
                    <span class="mt-4">* - Bu taxminiy summa bo'lib, aniq summa shartnomada ko'rsatiladi</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                    <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
                  </div>
          </div>
      </form>
  </header>
  <header class="d-flex justify-content-center" *ngIf="contractList.length">
    <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
      <div class="mb-3 mt-2">
        <app-calculation></app-calculation>
      </div>
    </form>
  </header>
</div>



