import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from './../../services/data-share.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit {

  public form: FormGroup;
  @ViewChild('phone') phone: ElementRef;

  constructor(private dataShareService: DataShareService, private router: Router) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        fio: new FormControl(null, Validators.required),
        phone: new FormControl(null, [Validators.required, Validators.minLength(17)]),
        email: new FormControl(null, [Validators.required, Validators.email]),
      }
    )
    if(localStorage.getItem('contact')) {
      const formData = JSON.parse(localStorage.getItem('contact'))
      this.form.patchValue(formData)
    } else {
      this.form.patchValue({phone: '+998'})
    }
  }

  onSubmit() {
    localStorage.setItem('contact', JSON.stringify(this.form.value))
    this.dataShareService.nextContacts(this.form.value)
    this.router.navigate(['/requisite'])
  }

  back() {
    this.router.navigate([localStorage.getItem('url')])
  }

  next() {
    this.router.navigate(['/requisite'])
  }

  /// Phone validate
  onKeyUp(e: any) {
    const value = this.phone.nativeElement.value
    if(!(/^\+[\d ]*$/.test(value))) {
      this.phone.nativeElement.value = value.slice(0, -1)
    }
  }

  onKey(e: any) {
    const value = this.phone.nativeElement.value
    if(e.which === 8) {
      return
    }

    const len = value.length
    if(len === 4 || len === 7 || len === 11 || len === 14) {
      this.phone.nativeElement.value = value + ' '
    }

    if(len >= 17) {
      e.preventDefault()
    }
  }

}
