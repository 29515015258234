
    <div class="container">
      <header class="d-flex justify-content-center">
          <form class="ps-5 pe-3 pt-4 mt-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="mb-3 mt-2">
              <h4 class="pt-4 text-center">Kontakt ma'lumotlari</h4>
              <div class="row pt-4">
                <div class="col-12">
                    <label for="">Arizachining F.I.SH:<span class="text-danger">*</span></label>
                    <input type="text" formControlName="fio" class="form-control border-0" id="fio">
                    <div *ngIf="form.get('fio').invalid && form.get('fio').touched" class="validation">
                      <small><i>F.I.SH </i> kiritilishi shart</small>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <label for="">Arizachining telefon raqami:<span class="text-danger">*</span></label>
                    <input type="text" formControlName="phone" #phone (keydown)="onKey($event)" (keyup)="onKeyUp($event)" class="form-control border-0" id="phone">
                    <div *ngIf="form.get('phone').invalid && form.get('phone').touched" class="validation">
                      <small *ngIf="form.get('phone').errors.required"><i>Arizachining telefon raqami</i> kiritilishi shart</small>
                      <small *ngIf="form.get('phone').errors.minlength">telefon raqam noto'g'ri formatda kiritildi</small>
                    </div>
                    <hr>
                </div>
                <div class="col-12">
                    <label for="">Arizachining elektron manzili:<span class="text-danger">*</span></label>
                    <input type="text" formControlName="email" class="form-control border-0" id="email">
                    <div *ngIf="form.get('email').invalid && form.get('email').touched" class="validation">
                      <small *ngIf="form.get('email').errors.required"><i>Arizachining elektron manzili</i> kiritilishi shart</small>
                      <small *ngIf="form.get('email').errors.email">emailni to'g'ri kiriting</small>
                    </div>
                    <hr>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="submit" class="btn btn-lg btn-success mt-3" [disabled]="form.invalid">Keyingi</button>
              </div>
            </div>
          </form>
      </header>
  </div>
