//Texnik topshiriqlarni axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha ekspertizadan o'tkazish
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-etrcicsr',
  templateUrl: './contract-etrcicsr.component.html',
  styleUrls: ['./contract-etrcicsr.component.scss']
})
export class ContractEtrcicsrComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 197690;
  public minValue: number = 18978240;
  public approximateAmount: number = 0;
  public roleValue: number = 0;
  public moduleValue: number = 0;
  public systemValue: number = 0;
  public comparedValue: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any
  @ViewChild('roleCount') roleCount: ElementRef
  @ViewChild('moduleCount') moduleCount: ElementRef
  @ViewChild('systemCount') systemCount: ElementRef


  constructor( private dataShareService: DataShareService, private router: Router ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        systemName: new FormControl(null, Validators.required),
        docType: new FormControl(null, Validators.required),
        roleCount: new FormControl(null, Validators.required),
        moduleCount: new FormControl(null, []),
        systemCount: new FormControl(null, [])
      }
    )
    if(localStorage.getItem('contract-etrcicsr')) {
      const formData = JSON.parse(localStorage.getItem('contract-etrcicsr'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })
  }

  onSubmit() {
    const tempSum = this.form.value.roleCount+this.form.value.moduleCount+this.form.value.systemCount;
    if(this.form.value.docType === 'TZ') {
      this.approximateAmount = tempSum*1000000;
    } else if(this.form.value.docType === 'CHTZ') {
      this.approximateAmount = tempSum*1000000+2000000;
    } else {
      this.approximateAmount = 0;
    }

    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    localStorage.setItem('title', "Texnik topshiriqlarni axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha ekspertizadan o'tkazish")
    localStorage.setItem('contract-etrcicsr', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'expertise')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Tizim nomi',
      'Hujjat turi',
      'Rollar soni',
      'Quyi tizim va modullar soni',
      'Bu tizim bilan integratsiya qilingan boshqa tizimlar soni',
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'systemName',
      'docType',
      'roleCount',
      'moduleCount',
      'systemCount'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

 // SET LIMIT

  onKeyroleCount(e: any) {
    const valueRole = this.roleCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenRole = valueRole.length

    if(lenRole >= 2) {
      e.preventDefault()
    }
  }

  onKeymoduleCount(e: any) {
    const valueModule = this.moduleCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenModule = valueModule.length

    if(lenModule >= 2) {
      e.preventDefault()
    }
  }

  onKeysystemCount(e: any) {
    const valueSystem = this.systemCount.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenSystem = valueSystem.length

    if(lenSystem >= 2) {
      e.preventDefault()
    }
  }

}
