
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Axborot xavfsizlik siyosatini ishlab chiqish</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Hudud:<span class="text-danger">*</span></label>
                    <select name="select" class="form-control w-100" formControlName="region">
                      <option value="" disabled></option>
                      <option value="1">Toshkent shahri</option>
                      <option value="2">Andijon viloyati</option>
                      <option value="3">Buxoro viloyati</option>
                      <option value="4">Farg'ona viloyati</option>
                      <option value="5">Jizzax viloyati</option>
                      <option value="6">Xorazm viloyati</option>
                      <option value="7">Namangan viloyati</option>
                      <option value="8">Navoiy viloyati</option>
                      <option value="9">Qashqadaryo viloyati</option>
                      <option value="10">Samarqand viloyati</option>
                      <option value="11">Sirdaryo viloyati</option>
                      <option value="12">Surxondaryo viloyati</option>
                      <option value="13">Toshkent viloyati</option>
                      <option value="14">Qoraqalpog'iston Respublikasi	</option>
                    </select>
                    <div *ngIf="form.get('region').invalid && form.get('region').touched" class="validation">
                      <small> <i>Hudud</i> tanlanishi shart</small>
                    </div>
                    <hr>
                  </div>
                  <div class="col-12">
                      <label for="">Til turi<span class="text-danger">*</span></label>
                      <select name="select" class="form-control w-100" formControlName="lang">
                        <option value=""></option>
                        <option value="Uzbek">Uzbek</option>
                        <option value="Russ">Russ</option>
                        <option value="Russ/Uzbek">Russ/Uzbek</option>
                      </select>
                      <div *ngIf="form.get('lang').invalid && form.get('lang').touched" class="validation">
                        <small><i>Til turi</i> tanlanishi shart</small>
                      </div>
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)*</span>
                      <hr>
                  </div>
              </div>
              <!-- <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                      <button type="button" class="btn btn-lg btn-outline-secondary w-100" [disabled]="!approximateAmount" (click)="addSystem()"> {{(!contractIndex ? 'Tizim qo`shish' : 'Tahrir qilish')}}</button>
                  </div>
              </div> -->
                  <div class="d-flex justify-content-start">
                    <span class="mt-4">* - Bu taxminiy summa bo'lib, aniq summa shartnomada ko'rsatiladi</span>
                  </div>
                  <div class="d-flex justify-content-between">
                    <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                    <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
                  </div>
          </div>
      </form>
  </header>
</div>



