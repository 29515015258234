import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-audit',
  templateUrl: './contract-audit.component.html',
  styleUrls: ['./contract-audit.component.scss']
})
export class ContractAuditComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 197690;
  public constTime: number = 20;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any


  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        workstation: new FormControl(null, Validators.required),
        server: new FormControl(null, [])
      }
    )
    if(localStorage.getItem('contract-audit')) {
      const formData = JSON.parse(localStorage.getItem('contract-audit'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })

    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    const tempSum = this.form.value.workstation*0.7*this.constValue + this.form.value.server*8*this.constValue+this.constTime*this.constValue;
    this.approximateAmount = tempSum

    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    localStorage.setItem('title', "Axborotlashtirish obyektlarining axborot xavfsizligi auditi")
    localStorage.setItem('contract-audit', JSON.stringify(this.form.value))
    localStorage.setItem('type', 'audit')
    localStorage.setItem('columnsMain', JSON.stringify( [
      'Ishchi stansiyalar soni',
      'Serverlar soni'
    ]))
    localStorage.setItem('columnsTemp', JSON.stringify([
      'workstation',
      'server'
  ]))
    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }

    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
  }

  back() {
    this.router.navigate(['/'])
  }

}
