import { ContractService } from './../contract.service';
import { DataShareService } from './../../services/data-share.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Email } from './../../../assets/js/smtp.js';
import { ExportService } from 'src/app/services/export.service';
declare let Email: any;

@Component({
  selector: 'app-requisite',
  templateUrl: './requisite.component.html',
  styleUrls: ['./requisite.component.scss']
})
export class RequisiteComponent implements OnInit {

  public form: FormGroup;
  @ViewChild('invoice') invoice: ElementRef
  @ViewChild('mfo') mfo: ElementRef
  @ViewChild('stir') stir: ElementRef
  @ViewChild('okpo') okpo: ElementRef
  public errorMessage: string = ""
  public contactsData: any
  public systemData: any;
  public dataList: any;
  public applicationNumber: any;
  public title: string;

  constructor(private dataShareService: DataShareService, private router: Router,
    private contractService: ContractService, private exportService: ExportService) {
      this.title = localStorage.getItem('title')
     }

  ngOnInit() {
    this.form = new FormGroup(
      {
        organization: new FormControl(null, Validators.required),
        jurAddress: new FormControl(null, Validators.required),
        factAddress: new FormControl(null, []),
        paymentType: new FormControl("To'g'ridan to'g'ri", []),
        invoice: new FormControl(null, [Validators.required, Validators.minLength(24), Validators.pattern('[0-9 ]+')]),
        at: new FormControl(null, [Validators.required]),
        mfo: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.pattern('[0-9 ]+')]),
        stir: new FormControl(null, [Validators.required, Validators.minLength(11), Validators.pattern('[0-9 ]+')]),
        okpo: new FormControl(null, [Validators.required, Validators.maxLength(8), Validators.minLength(6)]),
        date: new FormControl(new Date(), [])
      }
    )
    if(localStorage.getItem('requisite')) {
      const formData = JSON.parse(localStorage.getItem('requisite'))
      this.form.patchValue(formData)
    }

    this.dataShareService.sharedContacts.subscribe(contacts => {
      if(contacts) {
        this.contactsData = contacts
      }
    })
    this.dataShareService.sharedAccounts.subscribe(data => {
      if(data) {
        this.systemData = data
      }
    })
    this.dataShareService.sharedExportData.subscribe(data => {
      if(data) {
        this.dataList = data
      }
    })

    this.contractService.getApplications({type: localStorage.getItem('type')}).then(result => {
      this.applicationNumber = result.count;
      if(this.contactsData) {
        this.contactsData['number'] = Number(this.applicationNumber)+1;
      }
    })
  }

  onSubmit() {
    localStorage.setItem('requisite', JSON.stringify(this.form.value))
    this.dataShareService.nextRequisite(this.form.value)
    this.router.navigate(['/contacts'])
  }

  send() {
    this.form.value.date = (new Date()).getFullYear()+'-'+((((new Date()).getMonth()+1).toString().length === 1) ? ('0'+((new Date()).getMonth() + 1).toString()):((new Date()).getMonth() + 1))+'-'+
    ((new Date().getDate().toString().length === 1) ? ('0'+(new Date().getDate()).toString()):(new Date().getDate()))+' '+
    ((new Date().getHours().toString().length === 1) ? ('0'+(new Date().getHours()).toString()):(new Date().getHours()))+':'+
    ((new Date().getMinutes().toString().length === 1) ? ('0'+(new Date().getMinutes()).toString()):(new Date().getMinutes()))+':'+
    ((new Date().getSeconds().toString().length === 1) ? ('0'+(new Date().getSeconds()).toString()):(new Date().getSeconds()));

    this.form.value
    const params = {
      application: this.systemData,
      contact: {...this.contactsData, ...this.form.value},
      columnsTemp: JSON.parse(localStorage.getItem('columnsTemp')),
      columnsMain: JSON.parse(localStorage.getItem('columnsMain')),
      type: localStorage.getItem('type'),
      title: localStorage.getItem('title')
    }
    this.contractService.sendPhp(params).then((result: any) => {
      if(result.message) {
            localStorage.removeItem('system')
            localStorage.removeItem('contact')
            localStorage.removeItem('requisite')
            this.router.navigate(['/'])
            window.close();
            window.open("https://csec.uz/uz/services/", "_blank");
            this.form.reset()
      } else {
        this.errorMessage = "Что-то пошло не так!"
      }
    })
  }

  back() {
    localStorage.setItem('requisite', JSON.stringify(this.form.value))
    this.router.navigate(['/contacts'])
  }

  show() {
    this.form.value.date = (new Date()).getFullYear()+'-'+((((new Date()).getMonth()+1).toString().length === 1) ? ('0'+((new Date()).getMonth() + 1).toString()):((new Date()).getMonth() + 1))+'-'+
    ((new Date().getDate().toString().length === 1) ? ('0'+(new Date().getDate()).toString()):(new Date().getDate()))+' '+
    ((new Date().getHours().toString().length === 1) ? ('0'+(new Date().getHours()).toString()):(new Date().getHours()))+':'+
    ((new Date().getMinutes().toString().length === 1) ? ('0'+(new Date().getMinutes()).toString()):(new Date().getMinutes()))+':'+
    ((new Date().getSeconds().toString().length === 1) ? ('0'+(new Date().getSeconds()).toString()):(new Date().getSeconds()));
    this.dataList.other = this.form.value
    const columnsTemp = JSON.parse(localStorage.getItem('columnsTemp'));
    const columnsMain = JSON.parse(localStorage.getItem('columnsMain'));
    this.exportService.createPdf( columnsTemp, columnsMain, this.dataList, 0, this.systemData, localStorage.getItem('title'))
  }

  download() {
    this.form.value.date = (new Date()).getFullYear()+'-'+((((new Date()).getMonth()+1).toString().length === 1) ? ('0'+((new Date()).getMonth() + 1).toString()):((new Date()).getMonth() + 1))+'-'+
    ((new Date().getDate().toString().length === 1) ? ('0'+(new Date().getDate()).toString()):(new Date().getDate()))+' '+
    ((new Date().getHours().toString().length === 1) ? ('0'+(new Date().getHours()).toString()):(new Date().getHours()))+':'+
    ((new Date().getMinutes().toString().length === 1) ? ('0'+(new Date().getMinutes()).toString()):(new Date().getMinutes()))+':'+
    ((new Date().getSeconds().toString().length === 1) ? ('0'+(new Date().getSeconds()).toString()):(new Date().getSeconds()));
    this.dataList.other = this.form.value
    const columnsTemp = JSON.parse(localStorage.getItem('columnsTemp'));
    const columnsMain = JSON.parse(localStorage.getItem('columnsMain'));
    this.exportService.createPdf( columnsTemp, columnsMain, this.dataList, 1, this.systemData, localStorage.getItem('title'))
  }

  onKeysf(e: any) {
    const valueInvoice = this.invoice.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenInvoice = valueInvoice.length
    if(lenInvoice === 4 || lenInvoice === 9 || lenInvoice === 14 || lenInvoice === 19 || lenInvoice === 24 || lenInvoice === 29) {
      this.invoice.nativeElement.value = valueInvoice + ' '
    }

    if(lenInvoice >= 34) {
      e.preventDefault()
    }
  }

  onKeymfo(e: any) {
    const valueMfo = this.mfo.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenMfo = valueMfo.length
    if(lenMfo === 2) {
      this.mfo.nativeElement.value = valueMfo + ' '
    }

    if(lenMfo >= 6) {
      e.preventDefault()
    }
  }

  onKeystir(e: any) {
    const valueStir = this.stir.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenStir = valueStir.length
    if(lenStir === 3 || lenStir === 7) {
      this.stir.nativeElement.value = valueStir + ' '
    }

    if(lenStir >= 11) {
      e.preventDefault()
    }
  }

  onKeyokpo(e: any) {
    const valueOkpo = this.okpo.nativeElement.value
    if(e.which === 8) {
      return
    }

    const lenOkpo = valueOkpo.length
    if(lenOkpo >= 8) {
      e.preventDefault()
    }
  }

}
