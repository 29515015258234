//Konsalning hizmatlarini ko'rsatish
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataShareService } from 'src/app/services/data-share.service';

@Component({
  selector: 'app-contract-pcs',
  templateUrl: './contract-pcs.component.html',
  styleUrls: ['./contract-pcs.component.scss']
})
export class ContractPcsComponent implements OnInit {

  public form: FormGroup;
  public constValue: number = 790760;
  public approximateAmount: number = 0;
  public contractList: any[]=[];
  public contractIndex: number=0
  public tempData: any
  public requisiteData: any

  constructor( private dataShareService: DataShareService, private router: Router,
    private activatedRoute: ActivatedRoute ) { }

  ngOnInit() {
    this.form = new FormGroup(
      {
        serviceType: new FormControl(null, Validators.required)
      }
    )
    if(localStorage.getItem('contract-pcs')) {
      const formData = JSON.parse(localStorage.getItem('contract-pcs'))
      this.form.patchValue(formData)
      this.approximateAmount = Number(localStorage.getItem('approximateAmount'))
    }

    this.dataShareService.sharedEditIndex.subscribe(index => {
      if(index) {
        this.showContract(index-1)
        this.approximateAmount = 0;
      }
    })

    this.dataShareService.sharedRemoveIndex.subscribe(index => {
      if(index) {
        this.removeContract(index-1)
      }
    })

    this.dataShareService.sharedRequisite.subscribe(requisite => {
      if(requisite) {
        this.requisiteData = requisite
      }
    })

    this.dataShareService.sharedAccounts.subscribe(response => {
      if(response) {
        this.contractList = response;
      }
    })
    this.activatedRoute.queryParams.subscribe(params => {
      const url = params.url;
      if(url) {
        localStorage.setItem('url', url)
      } else {
        localStorage.setItem('url', '/')
      }
    })
  }

  onSubmit() {
    yearServices.map(item => {
      if(item.id==this.form.value.serviceType) {
        this.approximateAmount = item.sum;
        this.form.value.serviceType=item.name;
      }
    })
    // this.approximateAmount = this.constValue;
    this.form.value['sum']=this.approximateAmount
    localStorage.setItem('approximateAmount', this.approximateAmount.toString())
  }

  addSystem() {
    localStorage.setItem('title', "Konsalting xizmatlarini ko'rsatish")
    localStorage.setItem('contract-pcs', JSON.stringify(this.form.value))
    localStorage.setItem('columnsMain', JSON.stringify(['Xizmat turi']))
    localStorage.setItem('columnsTemp', JSON.stringify(['serviceType']))
    localStorage.setItem('type', 'interaction')

    if(!this.contractIndex) {
      this.contractList.push(this.form.value)
      this.form.reset()
      this.approximateAmount = 0
    } else {
      this.contractList[this.contractIndex-1]=this.form.value;
      this.contractIndex = 0;
      this.form.reset()
      this.approximateAmount = 0
    }
    this.dataShareService.nextAccounts(this.contractList)
    this.dataShareService.nextExportData({system: this.contractList, other: this.tempData})
    window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
  }

  showContract(index) {
    this.contractIndex = index+1;
    this.form.patchValue(this.contractList[index])
    this.approximateAmount = 0
  }

  removeContract(index) {
    this.contractList.splice(index,1)
    this.dataShareService.nextAccounts(this.contractList)
    localStorage.removeItem('system')
  }

  next() {
    this.router.navigate(['/contacts'])
    this.form.reset()
    this.approximateAmount = 0
  }

  back() {
    this.router.navigate(['/'])
  }

}


const yearServices = [
  {id: 1, sum: 9884500, name: "Tashkilotning normativ-huquqiy hujjatlarga muvofiq axborot va kiberxavfsizlik talablariga muvofiqligini baholash, shuningdek, o'zining Axborot xavfsizligi siyosatini amalga oshirishni baholash.(Vakolatli organlar tomonidan tekshirishdan oldin, shuningdek, axborot xavfsizligini tekshirishdan oldin oldindan amalga oshiriladi)"},
  {id: 2, sum: 3953800, name: "Inventarizatsiya, tashkilotning himoya ob'ektlarini xavfsizlik darajasi bo'yicha tasniflash, axborot va kiberxavfsizlik xavfini baholash"},
  {id: 3, sum: 3953800, name: "Tashkilotdagi konfidensial ma'lumotlar ro'yxatini va uni himoya qilishni ta'minlash choralarini aniqlash"},
  {id: 4, sum: 1500000, name: "Tashkilotda axborot va kiberxavfsizlikni ta'minlash uchun zarur bo'lgan ichki me'yoriy hujjatlar to'plamini ishlab chiqish"},
  {id: 5, sum: 830298, name: "Tashkilotda axborot va kiberxavfsizlikni ta'minlash uchun ichki me'yoriy-huquqiy hujjatlarni tayyorlashda yordam berish"},
  {id: 6, sum: 7907600, name: "Tashkilotning axborot va jihozlarni ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash"},
  {id: 7, sum: 6919150, name: "Tashkilotning axborot va kiberxavfsizlik hodisalarini qayd etishi va ularga javob berishini ta'minlash, shuningdek, ularning oqibatlarini bartaraf etishda konsultativ yordam berish"},
  {id: 8, sum: 197690, name: "Tashkilot xodimlarini axborot va kiberxavfsizlik bilan ta'minlash bo'yicha seminar-trening o'tkazish"},
]
