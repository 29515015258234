
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Konsalting xizmatlarini ko'rsatish</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <label for="">Xizmat turi:<span class="text-danger">*</span></label>
                    <select name="select" class="form-control w-100" formControlName="serviceType">
                      <option value="" disabled></option>
                      <option value="1">Tashkilotning normativ-huquqiy hujjatlarga muvofiq axborot va kiberxavfsizlik talablariga muvofiqligini baholash, shuningdek, o'zining Axborot xavfsizligi siyosatini amalga oshirishni baholash.
                        (Vakolatli organlar tomonidan tekshirishdan oldin, shuningdek, axborot xavfsizligini tekshirishdan oldin oldindan amalga oshiriladi)
                      </option>
                      <option value="2">Inventarizatsiya, tashkilotning himoya ob'ektlarini xavfsizlik darajasi bo'yicha tasniflash, axborot va kiberxavfsizlik xavfini baholash</option>
                      <option value="3">Tashkilotdagi maxfiy ma'lumotlar ro'yxatini va uni himoya qilishni ta'minlash choralarini aniqlash</option>
                      <option value="4">Tashkilotda Axborot xavfsizligi siyosatini ishlab chiqish, qayta ko'rib chiqish yoki yangilanishini baholashda yordam berish</option>
                      <option value="5">Tashkilotning axborot va kiber xavfsizligini ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash</option>
                      <option value="6">Tashkilotning axborot va jihozlarni ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash</option>
                      <option value="7">Tashkilotning axborot va kiberxavfsizlik hodisalarini qayd etishi va ularga javob berishini ta'minlash, shuningdek, ularning oqibatlarini bartaraf etishda konsultativ yordam berish</option>
                      <option value="8">Tashkilot xodimlarini axborot va kiberxavfsizlik bilan ta'minlash bo'yicha seminar-trening o'tkazish</option>
                    </select>
                    <div *ngIf="form.get('serviceType').invalid && form.get('serviceType').touched" class="validation">
                      <small> <i>Xizmat turi</i> tanlanishi shart</small>
                    </div>
                    <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Hisoblash</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)*</span>
                      <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-3"></div>
                  <div class="col-6">
                      <button type="button" class="btn btn-lg btn-outline-secondary w-100" [disabled]="!approximateAmount" (click)="addSystem()"> {{(!contractIndex ? 'Xizmat qo`shish' : 'Tahrir qilish')}}</button>
                  </div>
              </div>
              <div class="d-flex justify-content-start">
                <span class="mt-4">* - Bu taxminiy summa bo'lib, aniq summa shartnomada ko'rsatiladi</span>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
              </div>
          </div>
      </form>
  </header>
  <header class="d-flex justify-content-center" *ngIf="contractList.length">
    <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form">
      <div class="mb-3 mt-2">
        <app-calculation></app-calculation>
      </div>
    </form>
  </header>
</div>



