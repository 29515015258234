import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  private accounts = new BehaviorSubject(null);
  sharedAccounts = this.accounts.asObservable();

  private editIndex = new BehaviorSubject(null);
  sharedEditIndex = this.editIndex.asObservable();

  private removeIndex = new BehaviorSubject(null);
  sharedRemoveIndex = this.removeIndex.asObservable();

  private requisite = new BehaviorSubject(null);
  sharedRequisite = this.requisite.asObservable();

  private contacts = new BehaviorSubject(null);
  sharedContacts = this.contacts.asObservable();

  private exportData = new BehaviorSubject(null);
  sharedExportData = this.exportData.asObservable();

  constructor() { }

  nextAccounts(message: any) {
    this.accounts.next(message)
  }

  nextEditIndex(index: number) {
    this.editIndex.next(index)
  }

  nextRemoveIndex(index: number) {
    this.removeIndex.next(index)
  }

  nextRequisite(data: any) {
    this.requisite.next(data)
  }

  nextContacts(data: any) {
    this.contacts.next(data)
  }

  nextExportData(data: any) {
    this.exportData.next(data)
  }
}
