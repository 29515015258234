import { Injectable } from '@angular/core';
import { HttpService } from '../services/http.service';

@Injectable()
export class ContractService {

  constructor(private httpClient: HttpService){}

  public send(params) {
    return this.httpClient.postPromise('/contract', {
      params
    })
  }
   //// Production mode
  // public getApplications() {
  //   return this.httpClient.getPromise('http://shbform.space/php/api/read.php')
  // }

  // public sendPhp(params) {
  //   return this.httpClient.postPromise('http://shbform.space/php/api/create.php', {
  //     params
  //   })
  // }

  //// CSEC Production mode
 public getApplications(params) {
   return this.httpClient.getPromise('http://buyurtma.csec.uz/php/api/read.php', {
    params
  })
 }

 public sendPhp(params) {
   return this.httpClient.postPromise('http://buyurtma.csec.uz/php/api/create.php', {
     params
   })
 }

  // public getApplications(params) {
  //   return this.httpClient.getPromise('http://localhost/php/api/read.php', {
  //     params
  //   })
  // }

  // public sendPhp(params) {
  //   return this.httpClient.postPromise('http://localhost/php/api/create.php', {
  //     params
  //   })
  // }
}
