
    <div class="container">
      <header class="d-flex justify-content-center">
      <form class="ps-5 pe-3 pt-4 mt-3 pb-4 mb-3 contract-form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="mb-3 mt-2">
            <h4 class="pt-4 mb-4 text-center">Konsalting xizmatlarini ko'rsatish (yillik paketlar)</h4>
              <div class="row pt-4">
                  <div class="col-12">
                    <span>
                      Axborot va kiberxavfsizlik sohasida konsalting xizmatlar yillik paketi, yillik paket konsultativ yordam va amaliy ko'mak ko'rsatish nazarda tutilgan xizmatlarni  o'z  ichiga oladi:
                    </span>
                    <p>Tashkilotning me'yoriy-huquqiy hujjatlarga muvofiq axborot va kiberxavfsizlik talablariga muvofiqligi bo'yicha baholash o'tkazish;</p>
                    <p>Tashkilotning himoya ob'ektlarini xavfsizlik darajasi bo'yicha tasniflash, axborot va kiberxavfsizlik holatini baholash, invertarizatsiyzdan o'tkazish;</p>
                    <p>Tashkilotdagi konfidensial ma'lumotlar ro'yxatini va uni himoya qilishni ta'minlash choralarini aniqlash;</p>
                    <p>Tashkilotda Axborot xavfsizligi siyosatini ishlab chiqish, qayta ko'rib chiqish yoki yangilanishini baholashda yordam berish;</p>
                    <p>Tashkilotda axborot va kiberxavfsizlikni ta'minlash uchun ichki me'yoriy-huquqiy hujjatlarni tayyorlashda yordam berish;</p>
                    <p>Tashkilotni axborot va kiberxavfsizlik sohasida huquqiy qo'llab-quvvatlash;</p>
                    <p>Tashkilotning axborot va kiberxavfsizligini ta'minlash bo'yicha chora-tadbirlar rejasini tayyorlash;</p>
                    <p>Tashkiliy chora-tadbirlarni amalga oshirish va axborot xavfsizligi vositalarini joriy etishga ko'maklashish;</p>
                    <p>Tashkilotdagi axborot va kiberxavfsizlik hodisalarini hisobga olishni ta'minlash, shuningdek ularning oqibatlarini bartaraf etishda yordam berish;</p>
                    <p>Tashkilot xodimlarini axborot va kiberxavfsizlik bilan ta'minlash bo'yicha seminar-trening o'tkazish;</p>
                    <p>Axborot va kiberxavfsizlikni ta'minlash uchun mas'ul bo'lgan mutaxassislar uchun treninglar o'tkazish;</p>
                    <p>Axborot va kiberxavfsizlik monitoringi tizimida reyting ko'rsatkichlarini to'ldirish va oshirish bo'yicha maslahatlar;</p>
                    <hr>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                      <button type="submit" [disabled]="form.invalid" class="btn btn-lg btn-outline-primary">Summani ko'rish</button>
                  </div>
                  <div class="col-6">
                    <span>{{approximateAmount.toLocaleString()}} (so'm)</span>
                      <hr>
                  </div>
              </div>
              <div class="d-flex justify-content-between">
                <button type="button" class="btn btn-lg btn-success mt-3" (click)="back()">Orqaga</button>
                <button type="button" class="btn btn-lg btn-success mt-3" [disabled]="!contractList.length" (click)="next()">Keyingi</button>
              </div>
          </div>
      </form>
  </header>
</div>



